<template>
  <div>
    <location />
    <div class="page wrap-1200">
      <h3 class="subtitle">{{ shopinfo.shopname }}</h3>
      <GmapMap
        :center="{
          lat: parseFloat(shopinfo.maploc2),
          lng: parseFloat(shopinfo.maploc1),
        }"
        :zoom="15"
        map-type-id="roadmap"
        style="width: 100%; height: 300px"
      >
      </GmapMap>

      <div class="row row-cols-1 g-0 table-type header-col p-keep text-break">
        <div class="col col-sm-4 col-md-2">
          {{ $t("title.common.location") }}
        </div>
        <div class="col col-sm-8 col-md-4 shop-name">
          {{ shopinfo.shopname }}
        </div>
        <div class="col col-sm-4 col-md-2">
          {{ $t("title.common.location_type") }}
        </div>
        <div class="col col-sm-8 col-md-4">
          {{
            shopinfo.shoptype == 1
              ? $t("text.find_location.corporate_owned_shop")
              : $t("text.find_location.franchising_shop")
          }}
        </div>

        <div class="col col-sm-4 col-md-2">
          {{ $t("title.common.address") }}
        </div>
        <div class="col col-sm-8 col-md-4">
          {{ shopinfo.address }} {{ shopinfo.city }}
        </div>
        <div class="col col-sm-4 col-md-2">{{ $t("title.common.region") }}</div>
        <div class="col col-sm-8 col-md-4 d-flex align-items-center">
          {{ shopinfo.area || $t("text.common.unregistered") }}
        </div>

        <div class="col col-sm-4 col-md-2">
          {{ $t("title.common.post_code") }}
        </div>
        <div class="col col-sm-8 col-md-4 d-flex align-items-center">
          {{ shopinfo.zipcode || $t("text.common.unregistered") }}
        </div>
        <div class="col col-sm-4 col-md-2">{{ $t("title.common.email") }}</div>
        <div class="col col-sm-8 col-md-4">
          <a
            :href="'mailto:' + shopinfo.email"
            target="_blank"
            class="text-decoration-underline more"
            v-if="shopinfo.email"
          >
            {{ shopinfo.email }}
          </a>

          <a v-else>
            {{ $t("text.common.unregistered") }}
          </a>
        </div>

        <div class="col col-sm-4 col-md-2">{{ $t("title.common.phone") }}</div>
        <div class="col col-sm-8 col-md-4">
          {{ shopinfo.phone || $t("text.common.unregistered") }}
        </div>
        <div class="col col-sm-4 col-md-2">{{ $t("title.common.fax") }}</div>
        <div class="col col-sm-8 col-md-4">
          {{ shopinfo.fax || $t("text.common.unregistered") }}
        </div>

        <div class="col col-sm-4 col-md-2 border-bottom-md-0">
          {{ $t("title.common.homepage") }}
        </div>
        <div
          class="col col-sm-8 col-md-4 d-flex align-items-center border-bottom-md-0"
        >
          <a
            :href="shopinfo.website"
            target="_blank"
            class="text-decoration-underline more"
            v-if="shopinfo.website"
          >
            {{ shopinfo.website }}
          </a>
          <a v-else>
            {{ $t("text.common.unregistered") }}
          </a>
        </div>
        <div class="col col-sm-4 col-md-2 border-bottom border-bottom-sm-0">
          {{ $t("title.common.simulator_count") }}
        </div>
        <div class="col col-sm-8 col-md-4 d-flex align-items-center">
          {{ shopinfo.systemcnt | comma }}
        </div>
      </div>

      <div class="btn-bottom-wrapper">
        <b-button
          size="xl"
          variant="outline-primary"
          :aria-label="$t('button.common.list')"
          @click="
            $router.push({
              name: 'FavoriteLocation',
              query: {
                searchtype: $route.query.searchtype,
                searchname: $route.query.searchname,
              },
            })
          "
          >{{ $t("button.common.list") }}</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/membership";
import "@/filter/common";

export default {
  name: "FavoriteLocationInfo",
  components: {
    location,
  },
  data() {
    return {
      shopno: this.$route.params.shopno,
      shopinfo: [],
    };
  },
  methods: {
    get_membershopinfo() {
      ax.get_membershopinfo(this.shopno, (flag, data) => {
        if (flag) {
          this.shopinfo = data.shopinfo;
        } else {
          alert(data);
        }
      });
    },
  },
  created() {
    this.get_membershopinfo();
  },
};
</script>
