<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'MembershipMain',
  data() {
    return {
      token: sessionStorage.getItem('token') || null
    }
  },
  created() {
    if (this.token === null) this.$router.push({name: 'Login'})
  }
}
</script>

<style></style>
