var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('swiper',{staticClass:"swiper-tab-main",attrs:{"options":{
      slidesPerView: 'auto',
      freeMode: true,
      //initialSlide: tab - 1,
    }}},[_c('swiper-slide',{class:{ active: _vm.tab === 1 }},[_c('div',{on:{"click":function($event){_vm.$router.push('/membership/myaccount/myprofile').catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("button.membership.my_profile"))+" ")])]),_c('swiper-slide',{class:{ active: _vm.tab === 2 }},[_c('div',{on:{"click":function($event){_vm.$router
            .push('/membership/myaccount/favoritelocation')
            .catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("button.membership.favorite_location"))+" ")])]),_c('swiper-slide',{class:{ active: _vm.tab === 3 }},[_c('div',{on:{"click":function($event){_vm.$router
            .push('/membership/myaccount/confirmpassword')
            .catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("button.membership.cancel_membership"))+" ")])])],1),_c('ul',{staticClass:"nav nav-pills wrap-1200",attrs:{"id":"pills-tab","role":"tablist"}},[_c('li',{staticClass:"nav-item col-4",attrs:{"role":"presentation"}},[_c('button',{staticClass:"w-100 nav-link",class:{ active: _vm.$route.name === 'MyProfile' },attrs:{"id":"pills-1-tab","data-bs-toggle":"pill","data-bs-target":"#pills-1","type":"button","role":"tab","aria-controls":"pills-1","aria-selected":"true"},on:{"click":function($event){_vm.$router.push('/membership/myaccount/myprofile').catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("button.membership.my_profile"))+" ")])]),_c('li',{staticClass:"nav-item col-4",attrs:{"role":"presentation"}},[_c('button',{staticClass:"w-100 nav-link",class:{ active: _vm.$route.name === 'FavoriteLocation' },attrs:{"id":"pills-2-tab","data-bs-toggle":"pill","data-bs-target":"#pills-2","type":"button","role":"tab","aria-controls":"pills-2","aria-selected":"false"},on:{"click":function($event){_vm.$router
            .push('/membership/myaccount/favoritelocation')
            .catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("button.membership.favorite_location"))+" ")])]),_c('li',{staticClass:"nav-item col-4",attrs:{"role":"presentation"}},[_c('button',{staticClass:"w-100 nav-link",class:{ active: _vm.$route.name === 'CancelMember' },attrs:{"id":"pills-3-tab","data-bs-toggle":"pill","data-bs-target":"#pills-3","type":"button","role":"tab","aria-controls":"pills-3","aria-selected":"false"},on:{"click":function($event){_vm.$router
            .push('/membership/myaccount/confirmpassword')
            .catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t("button.membership.cancel_membership"))+" ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }