<template>
  <div>
    <location />
    <div class="page">
      <tab />
      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-1"
          role="tabpanel"
          aria-labelledby="pills-1-tab"
        >
          <div class="wrap-1200">
            <b-form @submit="onSubmit" class="filter-group">
              <div class="input-group flex-nowrap">
                <div class="date-search flex-grow-1 flex-sm-grow-0 ms-sm-auto">
                  <input
                    type="date"
                    name="startdate"
                    v-model="searchData.startdate"
                  />
                  <input
                    type="date"
                    name="enddate"
                    v-model="searchData.enddate"
                  />
                </div>
                <button
                  class="btn btn-primary btn-md icon-search"
                  type="submit"
                ></button>
              </div>
            </b-form>

            <table class="table board">
              <thead>
                <tr>
                  <th class="col-auto d-lg-table-cell">
                    {{ $t("title.common.location") }}
                  </th>
                  <th class="col-auto d-lg-table-cell">
                    {{ $t("title.common.course") }}
                  </th>
                  <th class="col-auto d-none d-lg-table-cell w-1">
                    {{ $t("title.common.hole") }}
                  </th>
                  <th class="col-auto d-none d-lg-table-cell w-1">
                    {{ $t("title.common.shot") }}
                  </th>
                  <th class="col-auto d-none d-lg-table-cell w-1">
                    {{ $t("title.common.date") }}
                  </th>
                  <th class="col-auto d-none d-lg-table-cell w-1">
                    {{ $t("title.common.status") }}
                  </th>
                  <th class="col-auto d-none d-sm-table-cell">
                    {{ $t("title.membership.scorecard") }}
                  </th>
                  <th class="th-arrow">&nbsp;</th>
                </tr>
              </thead>
              <tbody v-if="data_yn">
                <template v-for="(item, index) in items">
                  <tr :key="index">
                    <td
                      class="col-auto d-lg-table-cell text-start text-lg-center shop-name"
                    >
                      {{ item.shopname }}
                    </td>
                    <td class="col-auto d-lg-table-cell text-start">
                      {{ item.coursename }}
                    </td>
                    <td class="col-auto d-none d-lg-table-cell">
                      {{ item.hole + "H" }}
                    </td>
                    <td class="col-auto d-none d-lg-table-cell">
                      {{ item.shot | comma }}
                    </td>
                    <td
                      class="col-auto d-none d-lg-table-cell"
                    >
                      <template v-if="item.timezone_date.timezone_private">
                        <p class="text-date text-nowrap">
                          <dfn
                            class="time-zone-mark badge rounded-pill bg-orange"
                            title="Connect time"
                            >CT</dfn
                          >
                          {{ item.timezone_date.timezone_private | dateformat }}
                        </p>
                      </template>
                      <!-- 서비스 시간 -->
                      <p class="text-date text-nowrap">
                        <dfn
                          class="time-zone-mark badge rounded-pill bg-green"
                          title="Standard time"
                          >ST</dfn
                        >
                        {{ item.timezone_date.timezone_common | dateformat }}
                      </p>
                    </td>
                    <td class="col-auto d-none d-lg-table-cell">
                      {{ change_status(item.status) }}
                    </td>
                    <td class="col-auto d-none d-sm-table-cell">
                      <router-link
                        :to="{
                          name: 'MyRoundsView',
                          params: { id: item.gameno },
                          query: {
                            startdate: searchData.startdate,
                            enddate: searchData.enddate,
                          },
                        }"
                        class="btn btn-outline-primary btn-xs"
                        >{{ $t("button.membership.scorecard") }}</router-link
                      >
                    </td>
                    <td class="td-arrow" @click="toggle_tr(index)">
                      <img
                        src="/img/arrow_none_collapsed.png"
                        :class="{ 'btn-toggle-arrow': view_hide === index }"
                      />
                    </td>
                  </tr>
                  <tr
                    class="d-lg-none"
                    :class="{ show: view_hide === index }"
                    :key="index + 'r'"
                    v-if="view_hide === index"
                  >
                    <td colspan="7">
                      <div class="toggle-content">
                        <div class="row">
                          <div class="col-4">{{ $t("title.common.hole") }}</div>
                          <div class="col-8">
                            {{ item.hole + "H" }}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            {{ $t("title.common.shot") }}
                          </div>
                          <div class="col-8">
                            {{ item.shot | comma }}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">{{ $t("title.common.date") }}</div>
                          <div class="col-8">
                            <template
                              v-if="item.timezone_date.timezone_private"
                            >
                              <p class="text-date text-nowrap">
                                <dfn
                                  class="time-zone-mark badge rounded-pill bg-orange"
                                  title="Connect time"
                                  >CT</dfn
                                >
                                {{
                                  item.timezone_date.timezone_private
                                    | dateformat
                                }}
                              </p>
                            </template>
                            <!-- 서비스 시간 -->
                            <p class="text-date text-nowrap">
                              <dfn
                                class="time-zone-mark badge rounded-pill bg-green"
                                title="Standard time"
                                >ST</dfn
                              >
                              {{
                                item.timezone_date.timezone_common | dateformat
                              }}
                            </p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            {{ $t("title.common.status") }}
                          </div>
                          <div class="col-8">
                            {{ change_status(item.status) }}
                          </div>
                        </div>
                        <div class="row d-sm-none">
                          <div class="col-4">
                            {{ $t("title.membership.scorecard") }}
                          </div>
                          <div class="col-8">
                            <router-link
                              :to="{
                                name: 'MyRoundsView',
                                params: { id: item.gameno },
                                query: {
                                  startdate: searchData.startdate,
                                  enddate: searchData.enddate,
                                },
                              }"
                              class="btn btn-outline-primary btn-xs"
                              >{{
                                $t("button.membership.scorecard")
                              }}</router-link
                            >
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="7" class="text-center">
                    {{ $t("text.common.no_data") }}
                  </td>
                </tr>
              </tbody>
            </table>

            <b-pagination-nav
              :link-gen="linkGen"
              :number-of-pages="page_total"
              v-model="searchData.pageno"
              use-router
              aria-label="Page navigation"
              v-if="page_total > 1"
              first-class="prev"
              prev-class="prev"
              next-class="next"
              last-class="next"
              hide-ellipsis
              limit="10"
            >
              <template #first-text>
                <i class="material-icons">keyboard_double_arrow_left</i>
              </template>
              <template #prev-text>
                <i class="material-icons">navigate_before</i>
              </template>
              <template #next-text>
                <i class="material-icons">navigate_next</i>
              </template>
              <template #last-text>
                <i class="material-icons">keyboard_double_arrow_right</i>
              </template>
              <template #page="{ page, active }">
                <a v-if="active">{{ page }}</a>
                <a v-else>{{ page }}</a>
              </template>
            </b-pagination-nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import tab from "@/components/membership/Tab";
import ax from "@/api/membership";
import "@/filter/common";
import i18n from "@/i18n";

export default {
  name: "MyRounds",
  components: {
    location,
    tab,
  },
  data() {
    return {
      searchData: {
        pageno: parseInt(this.$route.query.page) || 1,
        startdate:
          this.$route.query.startdate ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].startdate) ||
          this.$moment().add(-1, "month").format("YYYY-MM-DD"),
        enddate:
          this.$route.query.enddate ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].enddate) ||
          this.$moment().format("YYYY-MM-DD"),
      },
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      rows: 10,
      items: [],
      view_hide: null,
    };
  },
  methods: {
    get_memberplaylist() {
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });
      ax.get_memberplaylist(this.searchData, (flag, data, code = "") => {
        if (flag) {
          if (data.playlistcnt <= 0) {
            this.data_yn = false;
            return false;
          } else {
            this.data_yn = true;
          }
          this.total_cnt = data.playlistcnt;
          this.page_total = Math.ceil(data.playlistcnt / this.rows);
          this.items = data.playlist;
        } else {
          this.data_yn = false;
          this.page_total = 0;
          if (code == "300") {
            this.$notify({
              group: "alert",
              type: "warn",
              text: data,
              duration: 5000,
              speed: 1000,
            });
          } else alert(data);
          if (code === 401) {
            this.$store.dispatch("logout");
            this.$router.push("/");
          }
          // alert(data);
        }
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      this.$router
        .push({
          query: {
            startdate: this.searchData.startdate,
            enddate: this.searchData.enddate,
          },
        })
        .catch(() => {});
      this.get_memberplaylist();
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
    change_status(status) {
      if (status === "1") return i18n.t("text.common.ready");
      else if (status === "2") return i18n.t("text.common.playing");
      else if (status === "3") return i18n.t("text.common.move");
      else if (status === "4") return i18n.t("text.common.terminated");
      else if (status === "5") return i18n.t("text.common.drop");
      else if (status === "6") return i18n.t("text.common.end");
      // else return "대기";
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
  },
  created() {
    this.get_memberplaylist();
  },
  watch: {
    "searchData.startdate": function () {
      this.searchData.pageno = 1;
      this.get_memberplaylist();
    },
    "searchData.enddate": function () {
      this.searchData.pageno = 1;
      this.get_memberplaylist();
    },
    "searchData.pageno": function () {
      this.get_memberplaylist();
    },
  },
};
</script>
