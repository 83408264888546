<template>
  <div>
    <swiper
      :options="{
        slidesPerView: 'auto',
        freeMode: true,
        //initialSlide: tab - 1,
      }"
      class="swiper-tab-main"
    >
      <swiper-slide :class="{ active: tab == 1 }"
        ><div
          @click="
            $router.push('/membership/myrecords/myrounds').catch(() => {})
          "
        >
          {{ $t("button.membership.my_rounds") }}
        </div></swiper-slide
      >
      <swiper-slide :class="{ active: tab == 2 }"
        ><div
          @click="
            $router.push('/membership/myrecords/mytournaments').catch(() => {})
          "
        >
          {{ $t("button.membership.my_tournaments") }}
        </div></swiper-slide
      >
      <swiper-slide :class="{ active: tab == 3 }"
        ><div
          @click="$router.push('/membership/myrecords/myevent').catch(() => {})"
        >
          {{ $t("button.membership.my_events") }}
        </div></swiper-slide
      >
      <!-- <swiper-slide :class="{ active: tab == 4 }"
        ><div
          @click="$router.push('/membership/myrecords/myxpoint').catch(() => {})"
        >
          {{ $t("text.common.xpoint") }}
        </div></swiper-slide
      > -->
    </swiper>
    <ul class="nav nav-pills wrap-1200" id="pills-tab" role="tablist">
      <li class="nav-item col-4" role="presentation">
        <button
          class="w-100 nav-link"
          id="pills-1-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-1"
          type="button"
          role="tab"
          aria-controls="pills-1"
          aria-selected="true"
          @click="
            $router.push('/membership/myrecords/myrounds').catch(() => {})
          "
          :class="{
            active:
              $route.name === 'MyRounds' || $route.name === 'MyRoundsView',
          }"
        >
          {{ $t("button.membership.my_rounds") }}
        </button>
      </li>
      <li class="nav-item col-4" role="presentation">
        <button
          class="w-100 nav-link"
          id="pills-2-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-2"
          type="button"
          role="tab"
          aria-controls="pills-2"
          aria-selected="false"
          @click="
            $router.push('/membership/myrecords/mytournaments').catch(() => {})
          "
          :class="{
            active:
              $route.name === 'MyTournaments' ||
              $route.name === 'MyTournamentsView' ||
              $route.name === 'MyTournamentsAttend' ||
              $route.name === 'MyScorecard' ||
              $route.name === 'MyTournamentsRanking',
          }"
        >
          {{ $t("button.membership.my_tournaments") }}
        </button>
      </li>
      <li class="nav-item col-4" role="presentation">
        <button
          class="w-100 nav-link"
          id="pills-3-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-3"
          type="button"
          role="tab"
          aria-controls="pills-3"
          aria-selected="false"
          @click="$router.push('/membership/myrecords/myevent').catch(() => {})"
          :class="{
            active:
              $route.name === 'MyEvent' ||
              $route.name === 'MyMiniGameRanking' ||
              $route.name === 'MyWinAMiniGame',
          }"
        >
          {{ $t("button.membership.my_events") }}
        </button>
      </li>
      <!-- <li class="nav-item col-3" role="presentation">
        <button
          class="w-100 nav-link"
          id="pills-4-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-4"
          type="button"
          role="tab"
          aria-controls="pills-4"
          aria-selected="false"
          @click="
            $router.push('/membership/myrecords/myxpoint').catch(() => {})
          "
          :class="{ active: $route.name === 'MyXpoint' }"
        >
          {{ $t("text.common.xpoint") }}
        </button>
      </li> -->
    </ul>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "Tab",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      /*swiperOptions: {
        slidesPerView: 'auto',
        freeMode: true
      }*/
    };
  },
  computed: {
    tab: function () {
      if (
        this.$route.name === "MyRounds" ||
        this.$route.name === "MyRoundsView"
      )
        return 1;
      else if (
        this.$route.name === "MyTournaments" ||
        this.$route.name === "MyTournamentsView" ||
        this.$route.name === "MyTournamentsAttend" ||
        this.$route.name === "MyScorecard" ||
        this.$route.name === "MyTournamentsRanking"
      )
        return 2;
      else if (
        this.$route.name === "MyEvent" ||
        this.$route.name === "MyMiniGameRanking" ||
        this.$route.name === "MyWinAMiniGame"
      )
        return 3;
      else return 4;
    },
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
};
</script>
