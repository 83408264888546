<template>
  <div>
    <location />
    <div class="page">
      <tab />
      <div class="tab-content wrap-1200" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-2"
          role="tabpanel"
          aria-labelledby="pills-2-tab"
        >
          <div class="tourn-banner my-4">
            <div class="card flex-md-row justify-content-md-between">
              <div class="col-md-4 card-img-wrapper order-md-2">
                <img
                  :src="
                    tournamentinfo.tourbinary &&
                    tournamentinfo.tourbinary[0] &&
                    tournamentinfo.tourbinary[0].filepath
                      | get_img(
                        'https://krstorage.s3.ap-northeast-2.amazonaws.com/files/tournament/06edda88fe1bb993db760ee25b8ad9fa.jpg'
                      )
                  "
                />
              </div>

              <div class="col-md-8 d-flex flex-column order-md-1">
                <div class="card-body">
                  <span class="tourn-type badge rounded-pill"
                    >{{ tournament_type(tournamentinfo.tourtype_fk) }}/
                    {{ tournamentinfo.tour_rank_type | tour_rank_type }}</span
                  >
                  <h3 class="card-title">
                    {{ tournamentinfo.title }}
                  </h3>
                  <!-- <p class="info mb-5">{{ tournamentinfo.description }}</p> -->
                </div>
                <div class="card-footer">
                  <div class="row gx-2 gy-1">
                    <div class="col-auto text-date">
                      <!-- 사용자 시간 -->
                      <dfn
                        class="time-zone-mark badge rounded-pill bg-orange"
                        title="Connect time"
                        >CT</dfn
                      >
                      {{
                        tournamentinfo.timezone_startdate &&
                        tournamentinfo.timezone_startdate.timezone_private
                          | dateformat
                      }}
                      ~
                      {{
                        tournamentinfo.timezone_startdate &&
                        tournamentinfo.timezone_enddate.timezone_private
                          | dateformat
                      }}
                    </div>
                    <div class="col-auto text-date">
                      <!-- 서비스 시간 -->
                      <dfn
                        class="time-zone-mark badge rounded-pill bg-green"
                        title="Standard time"
                        >ST</dfn
                      >
                      {{
                        tournamentinfo.timezone_startdate &&
                        tournamentinfo.timezone_startdate.timezone_common
                          | dateformat
                      }}
                      ~
                      {{
                        tournamentinfo.timezone_startdate &&
                        tournamentinfo.timezone_enddate.timezone_common
                          | dateformat
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <b-form
            @submit="onSubmit"
            class="filter-group row row-cols-2 row-cols-sm-auto g-2 justify-content-end mt-4"
          >
            <div class="col-sm-4" v-if="tournamentinfo.tour_rank_type == '0'">
              <div class="input-group">
                <select
                  class="form-select"
                  aria-label="Select one"
                  v-model="searchData.tour_rank_type"
                >
                  <option value="1">
                    {{ $t("filter.common.low_gross") }}
                  </option>
                  <option value="2">
                    {{ $t("filter.common.low_net") }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-sm-4">
              <select v-model="searchData.pagesize">
                <option value="10" selected>
                  {{ $t("filter.common.sort_by_10") }}
                </option>
                <option value="50">{{ $t("filter.common.sort_by_50") }}</option>
                <option value="100">
                  {{ $t("filter.common.sort_by_100") }}
                </option>
              </select>
            </div>
            <div class="col-sm-4 col-12">
              <div class="input-group">
                <select
                  class="form-select"
                  aria-label="Select one"
                  name="gender"
                  v-model="searchData.gender"
                >
                  <option value="0" :selected="searchData.gender === 0">
                    {{ $t("filter.common.total") }}
                  </option>
                  <option value="1" :selected="searchData.gender === 1">
                    {{ $t("filter.common.male") }}
                  </option>
                  <option value="2" :selected="searchData.gender === 2">
                    {{ $t("filter.common.female") }}
                  </option>
                </select>
                <button
                  class="btn btn-primary btn-md icon-search"
                  type="submit"
                ></button>
              </div>
            </div>
            <!-- <div class="col flex-fill">
              <ul class="tab-sub">
                <li
                  v-if="
                    tournamentinfo.tour_rank_type == 1 ||
                    tournamentinfo.tour_rank_type == 0
                  "
                >
                  <a class="on">{{ $t("button.common.stroke") }}</a>
                </li>
                <li v-if="tournamentinfo.tour_rank_type == 2">
                  <a class="on">{{ $t("button.common.stroke_handicap") }}</a>
                </li>
              </ul>
            </div> -->
          </b-form>

          <div class="row g-0 myrank">
            <div class="col-6 col-md-3">
              <ul class="box">
                <li>
                  <span class="rounded-circle bg-purple">
                    <i class="material-icons">military_tech</i>
                  </span>
                </li>
                <li>
                  <h2>
                    {{
                      rankInfo &&
                      rankInfo.tournamentrankinfo &&
                      rankInfo.tournamentrankinfo.rank | comma
                    }}<small>/{{ total_cnt || 0 }}</small>
                  </h2>
                  <p>{{ $t("text.common.my_ranking") }}</p>
                </li>
              </ul>
            </div>

            <div class="col-6 col-md-3">
              <ul class="box">
                <li>
                  <span class="rounded-circle bg-pink">
                    <i class="material-icons">scoreboard</i>
                  </span>
                </li>
                <li>
                  <h2>
                    {{
                      rankInfo &&
                      rankInfo.tournamentrankinfo &&
                      Math.sign(
                        rankInfo.tournamentrankinfo &&
                          rankInfo.tournamentrankinfo.strokelastscore
                      ) > 0
                        ? "+"
                        : ""
                    }}
                    {{
                      rankInfo &&
                      rankInfo.tournamentrankinfo &&
                      rankInfo.tournamentrankinfo.strokelastscore | toFixed
                    }}
                  </h2>

                  <p>
                    {{ $t("text.common.course_score") }}
                  </p>
                </li>
              </ul>
            </div>

            <div class="col-6 col-md-3">
              <ul class="box">
                <li>
                  <span class="rounded-circle bg-cyan">
                    <i class="material-icons">emoji_events</i>
                  </span>
                </li>
                <li>
                  <h2
                    v-if="
                      (tournamentinfo.tour_rank_type == '0' &&
                        searchData.tour_rank_type == '1') ||
                      tournamentinfo.tour_rank_type == '1'
                    "
                  >
                    {{
                      rankInfo &&
                      rankInfo.tournamentrankinfo &&
                      Math.sign(
                        rankInfo.tournamentrankinfo &&
                          rankInfo.tournamentrankinfo.avg_score
                      ) > 0
                        ? "+"
                        : ""
                    }}
                    {{
                      rankInfo &&
                      rankInfo.tournamentrankinfo &&
                      rankInfo.tournamentrankinfo.avg_score | toFixed
                    }}
                  </h2>
                  <h2
                    v-if="
                      (tournamentinfo.tour_rank_type == '0' &&
                        searchData.tour_rank_type == '2') ||
                      tournamentinfo.tour_rank_type == '2'
                    "
                  >
                    {{
                      rankInfo &&
                      rankInfo.tournamentrankinfo &&
                      rankInfo.tournamentrankinfo.low_net > 0
                        ? "+"
                        : ""
                    }}
                    {{
                      rankInfo &&
                      rankInfo.tournamentrankinfo &&
                      rankInfo.tournamentrankinfo.low_net | toFixed
                    }}
                  </h2>

                  <p
                    v-if="
                      (tournamentinfo.tour_rank_type == '0' &&
                        searchData.tour_rank_type == '1') ||
                      tournamentinfo.tour_rank_type == '1'
                    "
                  >
                    {{ $t("text.membership.avg_score") }}
                  </p>
                  <p
                    v-if="
                      (tournamentinfo.tour_rank_type == '0' &&
                        searchData.tour_rank_type == '2') ||
                      tournamentinfo.tour_rank_type == '2'
                    "
                  >
                    {{ $t("title.common.low_net_score") }}
                  </p>
                </li>
              </ul>
            </div>

            <div class="col-6 col-md-3">
              <ul class="box">
                <li>
                  <span class="rounded-circle bg-orange">
                    <i class="material-icons">flag</i>
                  </span>
                </li>
                <li>
                  <h2>
                    {{
                      rankInfo &&
                      rankInfo.tournamentrankinfo &&
                      rankInfo.tournamentrankinfo.entrycnt | comma
                    }}<small
                      >/{{
                        rankInfo &&
                        rankInfo.tournamentrankinfo &&
                        rankInfo.tournamentrankinfo.entrylimit
                          | comma
                          | transToValue("∞")
                      }}</small
                    >
                  </h2>
                  <p>{{ $t("text.common.no_of_play") }}</p>
                </li>
              </ul>
            </div>
          </div>

          <table class="table board">
            <thead>
              <tr
                v-if="
                  (tournamentinfo.tour_rank_type == '0' &&
                    searchData.tour_rank_type == '1') ||
                  tournamentinfo.tour_rank_type == '1'
                "
              >
                <th class="d-lg-table-cell">
                  {{ $t("title.common.ranking") }}
                </th>
                <th class="d-lg-table-cell">
                  {{ $t("title.common.nickname") }}
                </th>
                <th class="d-none d-lg-table-cell">
                  {{ $t("title.common.location") }}
                </th>
                <th class="d-none d-lg-table-cell">
                  {{ $t("title.common.course") }}
                </th>
                <th class="d-none d-sm-table-cell">
                  {{ $t("title.common.stroke_score") }}
                </th>
                <th class="d-none d-lg-table-cell">
                  {{ $t("title.common.no_of_play") }}
                </th>
                <!-- <th class="col-auto d-none d-lg-table-cell">
                  {{ $t("title.common.standard_point") }}
                </th> -->
                <!-- <th
                  class="col-auto d-none d-lg-table-cell"
                  v-if="tournamentinfo.oom_point != 0"
                >
                  {{ $t("title.common.oom_point") }}
                </th> -->
                <th class="th-arrow"></th>
              </tr>
              <tr
                v-if="
                  (tournamentinfo.tour_rank_type == '0' &&
                    searchData.tour_rank_type == '2') ||
                  tournamentinfo.tour_rank_type == '2'
                "
              >
                <th class="d-lg-table-cell">
                  {{ $t("title.common.ranking") }}
                </th>
                <th class="d-lg-table-cell">
                  {{ $t("title.common.nickname") }}
                </th>
                <th class="d-none d-lg-table-cell">
                  {{ $t("title.common.location") }}
                </th>
                <th class="d-none d-lg-table-cell">
                  {{ $t("title.common.course") }}
                </th>
                <th class="d-none d-sm-table-cell">
                  {{ $t("title.common.low_net_score") }} /
                  {{ $t("title.common.handi_score") }}
                </th>
                <th class="d-none d-lg-table-cell">
                  {{ $t("title.common.no_of_play") }}
                </th>
                <!-- <th class="col-auto d-none d-lg-table-cell">
                  {{ $t("title.common.standard_point") }}
                </th>

                <th
                  class="col-auto d-none d-lg-table-cell"
                  v-if="tournamentinfo.oom_point != 0"
                >
                  {{ $t("title.common.oom_point") }}
                </th> -->
                <!-- <th class="d-none d-lg-table-cell">
                  Final Score<br>(Correction value)
                </th> -->
                <th class="th-arrow"></th>
              </tr>
            </thead>

            <tbody
              v-if="
                (total_cnt > 0 &&
                  tournamentinfo.tour_rank_type == '0' &&
                  searchData.tour_rank_type == '1') ||
                (total_cnt > 0 && tournamentinfo.tour_rank_type == '1')
              "
            >
              <template v-for="(item, index) in items">
                <tr :key="index">
                  <td class="d-lg-table-cell">
                    <strong class="text-black">{{
                      item.tournamentrankinfo.rank
                    }}</strong>
                  </td>
                  <td class="d-lg-table-cell text-start">
                    <MemberInfo
                      :nickname="item.tournamentrankinfo.nickname"
                      :playerno="item.tournamentrankinfo.playerno"
                      :member_img="item.tournamentrankinfo.member_img"
                      :badge_img="item.tournamentrankinfo.badge_img"
                    />
                  </td>
                  <td class="d-none d-lg-table-cell shop-name">
                    {{
                      item.tournamentrankinfo.shopinfo &&
                      item.tournamentrankinfo.shopinfo.shopname
                    }}
                  </td>
                  <td class="d-none d-lg-table-cell">
                    <template v-for="(course, index_n) in item.coursescore">
                      <p :key="index_n + 'l1'">
                        {{ course.coursename }}
                      </p>
                    </template>
                  </td>
                  <td class="d-none d-sm-table-cell">
                    <template v-for="(course, index_n) in item.coursescore">
                      <p :key="index_n + 'l1'">
                        {{ course.strokescore }}
                      </p>
                    </template>
                  </td>
                  <td class="d-none d-lg-table-cell text-red">
                    {{ item.tournamentrankinfo.entrycnt }} /
                    {{ item.tournamentrankinfo.entrylimit | transToValue("∞") }}
                  </td>
                  <!-- <td class="col-auto d-none d-lg-table-cell text-point-sp">
                    {{
                      item.tournamentrankinfo.standard_points
                        | comma
                        | suffix("P")
                    }}
                  </td> -->

                  <!-- <td
                    class="col-auto d-none d-lg-table-cell text-point-op"
                    v-if="tournamentinfo.oom_point != 0"
                  >
                    {{
                      item.tournamentrankinfo.oom_points | comma | suffix("P")
                    }}
                  </td> -->
                  <td class="td-arrow" @click="toggle_tr(index)">
                    <img
                      src="/img/arrow_none_collapsed.png"
                      :class="{ 'btn-toggle-arrow': view_hide === index }"
                    />
                  </td>
                </tr>
                <tr
                  class="d-lg-none"
                  :class="{ show: view_hide === index }"
                  :key="index + 'r'"
                  v-if="view_hide === index"
                >
                  <td colspan="7">
                    <div class="toggle-content">
                      <div class="row">
                        <div class="col-4">
                          {{ $t("title.common.location") }}
                        </div>
                        <div class="col-8 shop-name">
                          {{
                            item.tournamentrankinfo.shopinfo &&
                            item.tournamentrankinfo.shopinfo.shopname
                          }}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          {{ $t("title.common.course") }}
                        </div>
                        <div class="col-4">
                          <template
                            v-for="(course, index_nm) in item.coursescore"
                          >
                            {{ index_nm > 0 ? " / " : "" }}
                            <span :key="index_nm + 'm'"
                              >{{ course.coursename }}
                            </span>
                          </template>
                        </div>
                      </div>

                      <div class="row d-sm-none">
                        <div class="col-4">
                          {{ $t("title.common.stroke_score") }}
                        </div>
                        <div class="col-8">
                          <template
                            v-for="(course, index_nm) in item.coursescore"
                          >
                            {{ index_nm > 0 ? " / " : "" }}
                            <span :key="index_nm + 'm'">
                              {{ course.strokescore }}</span
                            >
                          </template>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          {{ $t("title.common.no_of_play") }}
                        </div>
                        <div class="col-8 text-red">
                          {{ item.tournamentrankinfo.entrycnt | comma }} /
                          {{
                            item.tournamentrankinfo.entrylimit
                              | transToValue("∞")
                          }}
                        </div>
                      </div>
                      <!-- <div class="row">
                        <div class="col-4">
                          {{ $t("title.common.standard_point") }}
                        </div>
                        <div class="col-8 text-point-sp">
                          {{
                            item.tournamentrankinfo.standard_points
                              | comma
                              | suffix("P")
                          }}
                        </div>
                      </div> -->

                      <!-- <div class="row" v-if="tournamentinfo.oom_point != 0">
                        <div class="col-4">
                          {{ $t("title.common.oom_point") }}
                        </div>
                        <div class="col-8 text-point-op">
                          {{
                            item.tournamentrankinfo.oom_points
                              | comma
                              | suffix("P")
                          }}
                        </div>
                      </div> -->
                      <!-- <div class="row">
                <div class="col-6">Final Score<br>(Correction value)</div>
                <div class="col-6">
                  {{ item.tournamentrankinfo.newperiolastscore | comma }}
                </div>
              </div> -->
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
            <tbody
              v-else-if="
                (total_cnt > 0 &&
                  tournamentinfo.tour_rank_type == '0' &&
                  searchData.tour_rank_type == '2') ||
                (total_cnt > 0 && tournamentinfo.tour_rank_type == '2')
              "
            >
              <template v-for="(item, index) in items">
                <tr :key="index">
                  <td class="d-lg-table-cell">
                    <strong class="text-black">{{
                      item.tournamentrankinfo.rank
                    }}</strong>
                  </td>
                  <td class="d-lg-table-cell text-start">
                    <MemberInfo
                      :nickname="item.tournamentrankinfo.nickname"
                      :playerno="item.tournamentrankinfo.playerno"
                      :member_img="item.tournamentrankinfo.member_img"
                      :badge_img="item.tournamentrankinfo.badge_img"
                    />
                  </td>
                  <td class="d-none d-lg-table-cell shop-name">
                    {{
                      item.tournamentrankinfo.shopinfo &&
                      item.tournamentrankinfo.shopinfo.shopname
                    }}
                  </td>
                  <td class="d-none d-lg-table-cell">
                    <template v-for="(course, index_n) in item.coursescore">
                      <p :key="index_n + 'l1'">
                        {{ course.coursename }}
                      </p>
                    </template>
                  </td>
                  <td class="d-none d-sm-table-cell">
                    <template v-for="(course, index_n) in item.coursescore">
                      <p :key="index_n + 'l1'">
                        {{ course.low_net }} /

                        {{ hc_au(item.tournamentrankinfo.handicap_diff) }}
                      </p>
                    </template>
                  </td>
                  <td class="d-none d-lg-table-cell text-red">
                    {{ item.tournamentrankinfo.entrycnt | comma }} /
                    {{ item.tournamentrankinfo.entrylimit | transToValue("∞") }}
                  </td>
                  <!-- <td class="col-auto d-none d-lg-table-cell text-point-sp">
                    {{
                      item.tournamentrankinfo.standard_points
                        | comma
                        | suffix("P")
                    }}
                  </td> -->
                  <!-- <td
                    class="col-auto d-none d-lg-table-cell text-point-op"
                    v-if="tournamentinfo.oom_point != 0"
                  >
                    {{
                      item.tournamentrankinfo.oom_points | comma | suffix("P")
                    }}
                  </td> -->
                  <!-- <td class="d-none d-lg-table-cell">
            {{ item.tournamentrankinfo.newperiolastscore | comma }}
          </td> -->
                  <td class="td-arrow" @click="toggle_tr(index)">
                    <img
                      src="/img/arrow_none_collapsed.png"
                      :class="{ 'btn-toggle-arrow': view_hide === index }"
                    />
                  </td>
                </tr>
                <tr
                  class="d-lg-none"
                  :class="{ show: view_hide === index }"
                  :key="index + 'r'"
                  v-if="view_hide === index"
                >
                  <td colspan="7">
                    <div class="toggle-content">
                      <div class="row">
                        <div class="col-4">
                          {{ $t("title.common.location") }}
                        </div>
                        <div class="col-8 shop-name">
                          {{
                            item.tournamentrankinfo.shopinfo &&
                            item.tournamentrankinfo.shopinfo.shopname
                          }}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          {{ $t("title.common.course") }}
                        </div>
                        <div class="col-8">
                          <template
                            v-for="(course, index_n) in item.coursescore"
                          >
                            <p :key="index_n + 'l1'">
                              {{ course.coursename }}
                            </p>
                          </template>
                        </div>
                      </div>
                      <div class="row d-sm-none">
                        <div class="col-4">
                          {{ $t("title.common.low_net_score") }} /
                          {{ $t("title.common.handi_score") }}
                        </div>
                        <div class="col-8">
                          <template
                            v-for="(course, index_n) in item.coursescore"
                          >
                            <p :key="index_n + 'l1'">
                              {{ course.low_net }} /

                              {{ hc_au(item.tournamentrankinfo.handicap_diff) }}
                            </p>
                          </template>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          {{ $t("title.common.no_of_play") }}
                        </div>
                        <div class="col-8 text-red">
                          {{ item.tournamentrankinfo.entrycnt | comma }} /
                          {{
                            item.tournamentrankinfo.entrylimit
                              | transToValue("∞")
                          }}
                        </div>
                      </div>
                      <!-- <div class="row">
                        <div class="col-4">
                          {{ $t("title.common.standard_point") }}
                        </div>
                        <div class="col-8 text-point-sp">
                          {{
                            item.tournamentrankinfo.standard_points
                              | comma
                              | suffix("P")
                          }}
                        </div>
                      </div> -->

                      <!-- <div class="row" v-if="tournamentinfo.oom_point != 0">
                        <div class="col-4">
                          {{ $t("title.common.oom_point") }}
                        </div>
                        <div class="col-8 text-point-op">
                          {{
                            item.tournamentrankinfo.oom_points
                              | comma
                              | suffix("P")
                          }}
                        </div>
                      </div> -->
                      <!-- <div class="row">
                <div class="col-6">Final Score<br>(Correction value)</div>
                <div class="col-6">
                  {{ item.tournamentrankinfo.newperiolastscore | comma }}
                </div>
              </div> -->
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>

            <tbody v-else>
              <tr>
                <td
                  colspan="7"
                  class="text-center"
                  v-if="
                    tournamentinfo.holecount > 9 || searchData.scoretype < 2
                  "
                >
                  {{ $t("text.common.no_data") }}
                </td>
                <td colspan="7" class="text-center" v-else>
                  {{ $t("text.common.not_provided_9_hole_mode") }}
                </td>
              </tr>
            </tbody>
          </table>

          <b-pagination-nav
            :link-gen="linkGen"
            :number-of-pages="page_total"
            v-model="searchData.subpageno"
            use-router
            aria-label="Page navigation"
            v-if="page_total > 1"
            first-class="prev"
            prev-class="prev"
            next-class="next"
            last-class="next"
            hide-ellipsis
            limit="10"
          >
            <template #first-text>
              <i class="material-icons">keyboard_double_arrow_left</i>
            </template>
            <template #prev-text>
              <i class="material-icons">navigate_before</i>
            </template>
            <template #next-text>
              <i class="material-icons">navigate_next</i>
            </template>
            <template #last-text>
              <i class="material-icons">keyboard_double_arrow_right</i>
            </template>
            <template #page="{ page, active }">
              <a v-if="active">{{ page }}</a>
              <a v-else>{{ page }}</a>
            </template>
          </b-pagination-nav>

          <div class="btn-bottom-wrapper">
            <b-button
              size="xl"
              variant="outline-primary"
              :aria-label="$t('button.common.list')"
              @click="$router.push({ name: 'MyTournaments' }).catch(() => {})"
            >
              {{ $t("button.common.list") }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import tab from "@/components/membership/Tab";
import MemberInfo from "@/components/membership/MemberInfo";
import ax from "@/api/membership";
import tax from "@/api/tournament";
import "@/filter/common";
import { myMixin } from "@/mixin/myMixin";

export default {
  name: "MyTournamentsRanking",
  components: {
    location,
    tab,
    MemberInfo,
  },
  data() {
    return {
      searchData: {
        subpageno: 1,
        tournamentno: this.$route.params.id,
        scoretype:
          this.$route.query.scoretype ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].scoretype) ||
          1,
        gender:
          this.$route.query.gender ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].gender) ||
          0,
        pagesize:
          this.$route.query.pagesize ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].pagesize) ||
          10,
        tour_rank_type:
          this.$route.params.tour_rank_type ||
          this.$store.state.searchData.tour_rank_type ||
          "1",
      },
      page_total: 1,
      total_cnt: 0,

      rows: 10,
      items: [],
      rankInfo: [],
      view_hide: null,
      tournamentinfo: {},
      member: {
        playerno: this.$route.params.playerno,
        playerbasicinfo: [],
        playerpalyinfo: [],
      },
    };
  },
  methods: {
    get_membertournamentranklist() {
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });
      ax.get_membertournamentranklist(
        this.searchData,
        (flag, data, code = "") => {
          // console.log(flag, data);
          if (flag) {
            this.total_cnt = data.tournamentranklistcnt;
            this.page_total = Math.ceil(
              data.tournamentranklistcnt / this.searchData.pagesize
            );
            this.items = data.tournamentranklist;

            this.rankInfo = data.player;

            this.tournamentinfo = data.tournamentinfo;
          } else {
            this.page_total = 0;
            // console.log(this.searchData.scoretype);

            if (code == "300") {
              this.$notify({
                group: "alert",
                type: "warn",
                text: data,
                duration: 5000,
                speed: 1000,
              });
            }
            // alert(data);
          }
        }
      );
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.subpageno = 1;
      this.$router
        .push({
          query: {
            scoretype: this.searchData.scoretype,
            gender: this.searchData.gender,
          },
        })
        .catch(() => {});
    },
    change_type(type) {
      this.searchData.scoretype = type;
      this.view_hide = null;
      // this.searchData.pageno = 1;
      // this.view_hide = null;
      // this.$router
      //   .push({
      //     name: "MyTournamentsRanking",
      //     params: { id: this.searchData.tournamentno },
      //     query: {
      //       scoretype: this.searchData.scoretype,
      //       gender: this.searchData.gender,
      //     },
      //   })
      //   .catch(() => {});
    },
    getMemberInfo(playerno) {
      tax.get_playerinfo(playerno, (flag, data) => {
        if (flag) {
          this.member.playerno = playerno;
          this.member.playerbasicinfo = data.playerbasicinfo;
          this.member.playerpalyinfo = data.playerpalyinfo;
          this.$refs["memberInfoModal"].modalOuterStyle.zIndex = 9999;
          this.$refs["memberInfoModal"].show();
        } else {
          alert(data);
        }
      });
    },
  },
  created() {
    this.get_membertournamentranklist();
  },
  watch: {
    "searchData.pagesize": function () {
      this.searchData.subpageno = 1;
      this.get_membertournamentranklist();
    },
    "searchData.scoretype": function () {
      this.searchData.subpageno = 1;
      this.get_membertournamentranklist();
    },
    "searchData.gender": function () {
      this.searchData.subpageno = 1;
      this.get_membertournamentranklist();
    },
    "searchData.tour_rank_type": function () {
      this.searchData.pageno = 1;
      this.get_membertournamentranklist();
    },
    "searchData.subpageno": function () {
      this.get_membertournamentranklist();
    },
  },
  mixins: [myMixin],
};
</script>
