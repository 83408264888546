<template>
  <div>
    <location />
    <div class="page">
      <tab />
      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-3"
          role="tabpanel"
          aria-labelledby="pills-3-tab"
        >
          <div class="wrap-1200">
            <b-form @submit="onSubmit" class="filter-group">
              <div
                class="row row-cols-1 row-cols-md-2 g-2 flex-grow-1 flex-md-grow-0 ms-md-auto"
              >
                <div class="col date-search">
                  <input
                    type="date"
                    name="startdate"
                    v-model="searchData.startdate"
                  />
                  <input
                    type="date"
                    name="enddate"
                    v-model="searchData.enddate"
                  />
                </div>

                <div class="col">
                  <div class="row g-2">
                    <div class="col">
                      <select
                        aria-label="Select tournament status"
                        v-model="searchData.status"
                      >
                        <option value="">
                          {{ $t("filter.common.total") }}
                        </option>
                        <option value="0">
                          {{ $t("filter.common.ready") }}
                        </option>
                        <option value="1">
                          {{ $t("filter.common.playing") }}
                        </option>
                        <option value="2">
                          {{ $t("filter.common.completed") }}
                        </option>
                        <option value="3">
                          {{ $t("filter.common.cancel") }}
                        </option>
                      </select>
                    </div>
                    <div class="col col-md-auto">
                      <div class="input-group">
                        <select
                          aria-label="Select event type"
                          name="type"
                          v-model="searchData.type"
                          class="form-select"
                        >
                          <option value="0" :selected="searchData.type === 0">
                            {{ $t("filter.common.total") }}
                          </option>
                          <option value="1" :selected="searchData.type === 1">
                            {{ $t("filter.common.holeinone") }}
                          </option>
                          <option value="2" :selected="searchData.type === 2">
                            {{ $t("filter.common.albatross") }}
                          </option>
                          <option value="3" :selected="searchData.type === 3">
                            {{ $t("filter.common.eagle_p5") }}
                          </option>
                          <option value="4" :selected="searchData.type === 4">
                            {{ $t("filter.common.birdie_p5") }}
                          </option>
                          <option value="5" :selected="searchData.type === 5">
                            {{ $t("filter.common.par_p5") }}
                          </option>
                          <option value="6" :selected="searchData.type === 6">
                            {{ $t("filter.common.eagle_p4") }}
                          </option>
                          <option value="7" :selected="searchData.type === 7">
                            {{ $t("filter.common.birdie_p4") }}
                          </option>
                          <option value="8" :selected="searchData.type === 8">
                            {{ $t("filter.common.par_p4") }}
                          </option>
                          <option value="9" :selected="searchData.type === 9">
                            {{ $t("filter.common.longest") }}
                          </option>
                          <option value="10" :selected="searchData.type === 10">
                            {{ $t("filter.common.nearpin") }}
                          </option>
                          <option value="11" :selected="searchData.type === 11">
                            {{ $t("filter.common.longest_ranking") }}
                          </option>
                          <option value="12" :selected="searchData.type === 12">
                            {{ $t("filter.common.nearpin_ranking") }}
                          </option>
                        </select>
                        <button
                          class="btn btn-primary btn-md icon-search"
                          type="submit"
                        ></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-form>

            <table class="table board toggle-xxl">
              <thead>
                <tr>
                  <th class="d-xxl-table-cell">
                    {{ $t("title.common.title") }}
                  </th>
                  <!-- <th class="d-none d-sm-table-cell">
                    {{ $t("title.common.category") }}
                  </th> -->
                  <th class="d-none d-md-table-cell">
                    {{ $t("title.common.date") }}
                  </th>
                  <th class="d-none d-xxl-table-cell">
                    {{ $t("title.common.course") }}({{
                      $t("title.common.hole")
                    }})
                  </th>
                  <!-- <th class="d-none d-xxl-table-cell">
                    {{ $t("title.common.hole") }}
                  </th> -->
                  <th class="d-none d-lg-table-cell">
                    {{ $t("title.common.prizes") }}
                  </th>
                  <th class="d-none d-xxl-table-cell">
                    {{ $t("title.common.no_of_play") }}
                  </th>
                  <th class="d-none d-md-table-cell">
                    {{ $t("title.common.status") }}
                  </th>
                  <th class="d-xxl-table-cell w-1">
                    {{ $t("title.common.leaderboard") }}
                  </th>
                  <th class="th-arrow">&nbsp;</th>
                </tr>
              </thead>
              <tbody v-if="data_yn">
                <template v-for="(item, index) in items">
                  <tr :key="index">
                    <td class="d-xxl-table-cell text-start">
                      <a
                        class="d-inline-block pointer"
                        @click="showModal(item.rule_pk)"
                      >
                        <span class="fs-12 text-capitalize text-pink">{{
                          event_type(item.type)
                        }}</span
                        ><br />
                        <span class="more">{{ item.title }}</span>
                      </a>
                    </td>
                    <!-- <td class="d-none d-sm-table-cell text-capitalize">
                      {{ event_type(item.type) }}
                    </td> -->
                    <td class="w-1 d-none d-md-table-cell">
                      <p class="text-date">
                        <!-- 사용자 시간 -->
                        <dfn
                          class="time-zone-mark badge rounded-pill bg-orange"
                          title="Connect time"
                          >CT</dfn
                        >
                        {{
                          item.timezone_startdate &&
                          item.timezone_startdate.timezone_private | dateformat
                        }}
                        ~
                        {{
                          item.timezone_enddate &&
                          item.timezone_enddate.timezone_private | dateformat
                        }}
                      </p>
                      <p class="text-date">
                        <!-- 서비스 시간 -->
                        <dfn
                          class="time-zone-mark badge rounded-pill bg-green"
                          title="Standard time"
                          >ST</dfn
                        >
                        {{
                          item.timezone_startdate &&
                          item.timezone_startdate.timezone_common | dateformat
                        }}
                        ~
                        {{
                          item.timezone_enddate &&
                          item.timezone_enddate.timezone_common | dateformat
                        }}
                      </p>
                    </td>
                    <td class="d-none d-xxl-table-cell">
                      {{ item.coursename }} {{ item.subcoursename }}({{
                        item.hole + "H"
                      }})
                    </td>
                    <!-- <td class="d-none d-xxl-table-cell">
                      {{ item.hole + "H" }}
                    </td> -->
                    <td class="d-none d-lg-table-cell text-prize">
                      {{ item.type < 11 ? null || item.point : "Rank Prize" }}
                    </td>
                    <td class="d-none d-xxl-table-cell text-red">
                      <template v-if="item.type < 11">
                        {{ item.entrylimit | transToValue("∞") }}
                      </template>
                      <template v-else>
                        {{ item.entrycnt | transToValue() }} /
                        {{ item.entrylimit | transToValue("∞") }}
                      </template>
                    </td>
                    <td class="d-none d-md-table-cell">
                      {{ event_status(item.status_cd) }}
                    </td>
                    <td class="d-xxl-table-cell">
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-xs w-75"
                        v-if="item.type > 10"
                        @click="
                          $router.push({
                            name: 'MyMiniGameRanking',
                            params: { eventno: item.rule_pk },
                          })
                        "
                      >
                        {{ $t("button.common.ranking") }}
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-xs w-75"
                        v-else
                        @click="
                          $router.push({
                            name: 'MyWinAMiniGame',
                            params: { eventno: item.rule_pk },
                          })
                        "
                      >
                        {{ $t("button.common.view") }}
                      </button>
                    </td>
                    <td class="td-arrow" @click="toggle_tr(index)">
                      <img
                        src="/img/arrow_none_collapsed.png"
                        :class="{ 'btn-toggle-arrow': view_hide === index }"
                      />
                    </td>
                  </tr>
                  <tr
                    class="d-xxl-none"
                    :class="{ show: view_hide === index }"
                    :key="index + 'r'"
                    v-if="view_hide === index"
                  >
                    <td colspan="7">
                      <div class="toggle-content">
                        <!--                         <div class="row d-sm-none">
                          <div class="col-4">
                            {{ $t("title.common.category") }}
                          </div>
                          <div class="col-8 text-capitalize">{{ event_type(item.type) }}</div>
                        </div> -->
                        <div class="row d-md-none">
                          <div class="col-4">{{ $t("title.common.date") }}</div>
                          <div class="col-8">
                            <p class="text-date">
                              <!-- 사용자 시간 -->
                              <dfn
                                class="time-zone-mark badge rounded-pill bg-orange"
                                title="Connect time"
                                >CT</dfn
                              >
                              {{
                                item.timezone_startdate &&
                                item.timezone_startdate.timezone_private
                                  | dateformat
                              }}
                              ~
                              {{
                                item.timezone_enddate &&
                                item.timezone_enddate.timezone_private
                                  | dateformat
                              }}
                            </p>
                            <p class="text-date">
                              <!-- 서비스 시간 -->
                              <dfn
                                class="time-zone-mark badge rounded-pill bg-green"
                                title="Standard time"
                                >ST</dfn
                              >
                              {{
                                item.timezone_startdate &&
                                item.timezone_startdate.timezone_common
                                  | dateformat
                              }}
                              ~
                              {{
                                item.timezone_enddate &&
                                item.timezone_enddate.timezone_common
                                  | dateformat
                              }}
                            </p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            {{ $t("title.common.course") }}
                          </div>
                          <div class="col-8">
                            {{ item.coursename }} {{ item.subcoursename }}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">{{ $t("title.common.hole") }}</div>
                          <div class="col-8">
                            {{ item.hole + "H" }}
                          </div>
                        </div>
                        <div class="row d-lg-none">
                          <div class="col-4">
                            {{ $t("title.common.prizes") }}
                          </div>
                          <div class="col-8 text-prize">
                            {{
                              item.type < 11 ? null || item.point : "Rank Prize"
                            }}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            {{ $t("title.common.no_of_play") }}
                          </div>
                          <div class="col-8 text-red">
                            <template v-if="item.type < 11">
                              {{ item.entrylimit | transToValue("∞") }}
                            </template>

                            <template v-else>
                              {{ item.entrycnt | transToValue() }} /
                              {{ item.entrylimit | transToValue("∞") }}
                            </template>
                          </div>
                        </div>
                        <div class="row d-md-none">
                          <div class="col-4">
                            {{ $t("title.common.status") }}
                          </div>
                          <div class="col-8">
                            {{ event_status(item.status_cd) }}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="7" class="text-center">
                    {{ $t("text.common.no_data") }}
                  </td>
                </tr>
              </tbody>
            </table>

            <TermDefinition getname="event" />

            <b-pagination-nav
              :link-gen="linkGen"
              :number-of-pages="page_total"
              v-model="searchData.pageno"
              use-router
              aria-label="Page navigation"
              v-if="page_total > 1"
              first-class="prev"
              prev-class="prev"
              next-class="next"
              last-class="next"
              hide-ellipsis
              limit="10"
            >
              <template #first-text>
                <i class="material-icons">keyboard_double_arrow_left</i>
              </template>
              <template #prev-text>
                <i class="material-icons">navigate_before</i>
              </template>
              <template #next-text>
                <i class="material-icons">navigate_next</i>
              </template>
              <template #last-text>
                <i class="material-icons">keyboard_double_arrow_right</i>
              </template>
              <template #page="{ page, active }">
                <a v-if="active">{{ page }}</a>
                <a v-else>{{ page }}</a>
              </template>
            </b-pagination-nav>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-minigame-info"
      ref="modal-minigame-info"
      size="xl"
      centered
      scrollable
      footer-class="btn-bottom-wrapper"
    >
      <template #modal-header>
        <h3>{{ $t("menu.event.minigame_informaition") }}</h3>
        <b-button
          variant="close"
          :aria-label="$t('button.common.close')"
          @click="$bvModal.hide('modal-minigame-info')"
        >
        </b-button>
      </template>

      <span class="mb-2 badge bg-pink text-capitalize">
        {{ event_type(eventinfo.type) }}
      </span>
      <p class="modal-subtitle">
        {{ eventinfo.title }}
      </p>

      <div class="row row-cols-1 row-cols-lg-auto gx-2 gy-1 lh-sm">
        <div class="col text-date">
          <!-- 사용자 시간 -->
          <dfn
            class="time-zone-mark badge rounded-pill bg-orange"
            title="Connect time"
            >CT</dfn
          >
          {{
            eventinfo.timezone_startdate &&
            eventinfo.timezone_startdate.timezone_private | dateformat
          }}
          ~
          {{
            eventinfo.timezone_enddate &&
            eventinfo.timezone_enddate.timezone_private | dateformat
          }}
        </div>
        <div class="col text-date">
          <!-- 서비스 시간 -->
          <dfn
            class="time-zone-mark badge rounded-pill bg-green"
            title="Standard time"
            >ST</dfn
          >
          {{
            eventinfo.timezone_startdate &&
            eventinfo.timezone_startdate.timezone_common | dateformat
          }}
          ~
          {{
            eventinfo.timezone_enddate &&
            eventinfo.timezone_enddate.timezone_common | dateformat
          }}
        </div>
      </div>

      <div class="row g-0 table-type header-col p-keep mt-3">
        <div class="col-sm-4">
          {{ $t("title.common.course") }}
        </div>
        <div class="col-sm-8">
          {{ eventinfo.coursename }} [{{ eventinfo.description }}]
        </div>

        <div class="col-sm-4">
          {{ $t("title.common.hole") }}
        </div>
        <div class="col-sm-8">{{ eventinfo.hole }}H</div>

        <div class="col-sm-4">
          {{ $t("title.common.level") }}
        </div>
        <div class="col-sm-8">
          {{ event_level(eventinfo.level_fk) }}
        </div>

        <div class="col-sm-4">
          {{ $t("title.common.entry_limit") }}
        </div>
        <div class="col-sm-8 text-red">
          {{ eventinfo.entrylimit | transToValue("∞") }}
        </div>

        <div class="col-sm-4">
          {{ $t("title.common.teebox") }}
        </div>
        <div class="col-sm-8">
          <div class="row row-cols-1 row-cols-lg-auto gy-2">
            <div class="col">
              {{ $t("text.common.male") }} :
              {{ eventinfo.teeboxman | teeboxType }}
            </div>
            <div class="col">
              {{ $t("text.common.female") }} :
              {{ eventinfo.teeboxwoman | teeboxType }}
            </div>
          </div>
        </div>
        <template v-if="eventinfo.type == '9' || eventinfo.type == '10'">
          <div class="col-sm-4">
            {{ $t("title.common.distance") }}
          </div>
          <div class="col-sm-8">
            {{
              eventinfo.distance != "0"
                ? eventinfo.distance + "m"
                : $t("text.common.unregistered")
            }}
          </div>
        </template>
        <div class="col-sm-4">
          {{ $t("title.common.prizes") }}
        </div>
        <div class="col-sm-8 text-prize" v-if="eventinfo.type < 11">
          {{ eventinfo.point }}
        </div>
        <div class="col-sm-8 text-point" v-else>
          <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-3 g-2 g-xl-3">
            <template v-for="(item, index) in awardprizeranklist">
              <div :key="'a' + index" class="col">
                <div class="card h-100">
                  <div class="card-body w-100">
                    <h6 class="card-title fs-6">
                      {{ $t("title.common.top_rank") }} {{ item.rank_no }}
                    </h6>
                    <p class="card-text pt-1 lh-sm text-prize">
                      {{ item.prize }}
                    </p>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <!-- <template v-if="eventinfo.standard_point != 0">
          <div class="col-sm-4">
            {{ $t("title.common.point_allocation") }}
          </div>
          <div class="col-sm-8 text-point-sp">
            {{ $t("title.common.standard_point") }}
          </div>
        </template> -->
      </div>
      <template #modal-footer="{}">
        <b-button
          size="md"
          variant="outline-secondary"
          :aria-label="$t('button.common.close')"
          @click="$bvModal.hide('modal-minigame-info')"
        >
          {{ $t("button.common.close") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import location from "@/components/Location";
import tab from "@/components/membership/Tab";
import TermDefinition from "@/components/TermDefinition";
import ax from "@/api/membership";
import { myMixin } from "@/mixin/myMixin";
import "@/filter/common";

export default {
  name: "MyEvent",
  components: {
    location,
    tab,
    TermDefinition,
  },
  data() {
    return {
      searchData: {
        pageno: this.$route.query.pageno || 1,
        status:
          this.$route.query.status ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].status) ||
          "",
        startdate:
          this.$route.query.startdate ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].startdate) ||
          this.$moment(new Date().setMonth(new Date().getMonth() - 1)).format(
            "YYYY-MM-DD"
          ),
        enddate:
          this.$route.query.enddate ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].enddate) ||
          this.$moment(new Date()).format("YYYY-MM-DD"),
        type:
          this.$route.query.type ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].type) ||
          0,
      },
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      rows: 10,
      items: [],
      view_hide: null,
      eventinfo: {},
      awardprizeranklist: [],
    };
  },
  mixins: [myMixin],
  methods: {
    get_membereventwinninglist() {
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });
      ax.get_membereventwinninglist(
        this.searchData,
        (flag, data, code = "") => {
          // console.log(flag, data);
          if (flag) {
            if (data.eventwvinninglistcnt <= 0) {
              this.data_yn = false;
              return false;
            } else {
              this.data_yn = true;
            }
            this.total_cnt = data.eventwvinninglistcnt;
            this.page_total = Math.ceil(data.eventwvinninglistcnt / this.rows);
            this.items = data.eventwvinninglist;
          } else {
            this.data_yn = false;
            this.page_total = 0;
            if (code == "300") {
              // alert(data);
              this.$notify({
                group: "alert",
                type: "warn",
                text: data,
                duration: 5000,
                speed: 1000,
              });
            }
          }
        }
      );
    },
    showModal(eventno) {
      // console.log(hole,type);
      ax.get_eventview(eventno, (flag, data) => {
        console.log(flag, data);
        if (flag) {
          this.eventinfo = data.eventinfo;
          this.eventinfo.startdate = this.$moment(
            this.eventinfo.startdate
          ).format("YYYY-MM-DD");
          this.eventinfo.enddate = this.$moment(this.eventinfo.enddate).format(
            "YYYY-MM-DD"
          );
          this.awardprizeranklist = data.awardprizerank;

          this.$refs["modal-minigame-info"].show();
        } else {
          alert(data.message);
        }
      });
    },
    hideModal() {
      this.$refs["modal-minigame-info"].hide();
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      this.$router
        .push({
          query: {
            startdate: this.searchData.startdate,
            enddate: this.searchData.enddate,
            type: this.searchData.type,
            status: this.searchData.status,
          },
        })
        .catch(() => {});
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
  },
  created() {
    this.get_membereventwinninglist();
  },
  watch: {
    "searchData.status": function () {
      this.searchData.pageno = 1;
      this.get_membereventwinninglist();
    },
    "searchData.startdate": function () {
      this.searchData.pageno = 1;
      this.get_membereventwinninglist();
    },
    "searchData.enddate": function () {
      this.searchData.pageno = 1;
      this.get_membereventwinninglist();
    },
    "searchData.type": function () {
      this.searchData.pageno = 1;
      this.get_membereventwinninglist();
    },

    "searchData.pageno": function () {
      this.get_membereventwinninglist();
    },
  },
};
</script>
