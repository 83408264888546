<template>
  <div>
    <location />

    <div class="p14 page">
      <account-tab />
      <div class="tab-content wrap-1200" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-3"
          role="tabpanel"
          aria-labelledby="pills-3-tab"
        >
          <p class="start-m">
            {{ $t("text.membership.drop_membership_1") }}
          </p>
          <span class="lh-base">
            {{ $t("text.membership.drop_membership_2") }}
          </span>
          <span class="icon-alarm">
            {{ $t("text.membership.drop_membership_3") }}
          </span>

          <!--      <div class="row g-0 flex-nowrap center-btn wrap-1200 justify-content-center">
            <div class="col-lg-3">
              <button
                class="btn btn-outline-secondary btn-xl"
                type="button"
                @click="$router.push({ name: 'main' })"
              >
                취소
              </button>
            </div>
            <div class="col-lg-3">
              <button
                class="btn btn-secondary btn-xl"
                type="button"
                @click="drop_member()"
              >
                회원탈퇴
              </button>
            </div>
          </div> -->
          <div class="btn-bottom-wrapper">
            <b-button
              size="xl"
              variant="outline-primary"
              aria-label=""
              @click="$router.push({ name: 'main' })"
              >{{ $t("button.common.cancel") }}</b-button
            >
            <b-button
              size="xl"
              variant="primary"
              aria-label=""
              @click="drop_member()"
              >{{ $t("button.membership.cancel_membership") }}</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import AccountTab from "@/components/membership/AccountTab";
import ax from "@/api/membership";

export default {
  name: "CancelMember",
  components: {
    location,
    AccountTab,
  },
  data() {
    return {
      form: {},
    };
  },
  props: ["password_yn"],
  methods: {
    step_chk() {
      if (this.password_yn !== "y") {
        alert(
          this.$i18n.t("alert.common.field_check", {
            field: this.$i18n.t("title.common.password"),
          })
        );
        this.$router.push({ name: "ConfirmPassword" });
      }
    },
    get_memberinfo() {
      ax.get_memberinfo((flag, data) => {
        if (flag) {
          this.form = data.memberinfo;
        } else {
          alert(data);
        }
      });
    },
    drop_member() {
      ax.member_drop((flag, data) => {
        if (flag) {
          alert(data);
          this.$store.dispatch("logout");
          this.$router.push({ name: "main" });
        } else {
          alert(data);
        }
      });
    },
  },
  created() {
    this.step_chk();
    this.get_memberinfo();
  },
};
</script>
