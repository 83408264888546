<template>
  <div>
    <location />
    <div class="page mt-0 login">
      <b-form @submit="onSubmit" class="d-flex flex-column wrap">
        <input
          type="password"
          class="form-control form-control-lg mb-3"
          :placeholder="$t('text.membership.check_password')"
          aria-label=""
          v-model="password"
        />
        <input
          class="btn btn-primary btn-lg w-100 mb-4"
          type="submit"
          :value="$t('button.common.confirm')"
        />
        <p class="text-subscript text-center fs-14">
          <span class="material-icons-outlined me-1">info</span>
          <span>{{ $t("text.membership.security_password") }}</span>
        </p>
      </b-form>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/membership";

export default {
  name: "ConfirmPassword",
  components: {
    location,
  },
  data() {
    return {
      password: "",
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      if (this.password === "") {
        alert(
          this.$i18n.t("alert.common.field_enter", {
            field: this.$i18n.t("title.common.password"),
          })
        );
        return false;
      }

      ax.memberpwd_recheck(this.password, (flag, data, message) => {
        if (flag) {
          alert(message);
          this.$router.push({
            name: "CancelMember",
            params: { password_yn: "y" },
          });
        } else {
          alert(message);
          return false;
        }
      });
    },
  },
};
</script>