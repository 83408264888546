<template>
  <div>
    <location />
    <div class="page">
      <tab />
      <div class="tab-content wrap-1200" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-2"
          role="tabpanel"
          aria-labelledby="pills-2-tab"
        >
          <div class="tourn-banner my-4">
            <div class="card flex-md-row justify-content-md-between">
              <div class="col-md-4 card-img-wrapper order-md-2">
                <img
                  :src="
                    tournamentinfo.tourbinary &&
                    tournamentinfo.tourbinary[0] &&
                    tournamentinfo.tourbinary[0].filepath
                      | get_img(
                        'https://krstorage.s3.ap-northeast-2.amazonaws.com/files/tournament/06edda88fe1bb993db760ee25b8ad9fa.jpg'
                      )
                  "
                />
              </div>

              <div class="col-md-8 d-flex flex-column order-md-1">
                <div class="card-body">
                  <span class="tourn-type badge rounded-pill"
                    >{{ tournament_type(tournamentinfo.tourtype_fk) }} /
                    {{ tournamentinfo.tour_rank_type | tour_rank_type }}
                  </span>
                  <h3 class="card-title">
                    {{ tournamentinfo.title }}
                  </h3>
                  <!-- <p class="info mb-5">{{ tournamentinfo.description }}</p> -->
                </div>
                <div class="card-footer">
                  <div class="row gx-2 gy-1">
                    <div class="col-auto text-date">
                      <!-- 사용자 시간 -->
                      <dfn
                        class="time-zone-mark badge rounded-pill bg-orange"
                        title="Connect time"
                        >CT</dfn
                      >
                      {{
                        tournamentinfo.timezone_startdate &&
                        tournamentinfo.timezone_startdate.timezone_private
                          | dateformat
                      }}
                      ~
                      {{
                        tournamentinfo.timezone_startdate &&
                        tournamentinfo.timezone_enddate.timezone_private
                          | dateformat
                      }}
                    </div>
                    <div class="col-auto text-date">
                      <!-- 서비스 시간 -->
                      <dfn
                        class="time-zone-mark badge rounded-pill bg-green"
                        title="Standard time"
                        >ST</dfn
                      >
                      {{
                        tournamentinfo.timezone_startdate &&
                        tournamentinfo.timezone_startdate.timezone_common
                          | dateformat
                      }}
                      ~
                      {{
                        tournamentinfo.timezone_startdate &&
                        tournamentinfo.timezone_enddate.timezone_common
                          | dateformat
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <table class="table board">
            <thead>
              <tr>
                <th class="d-lg-table-cell">
                  {{ $t("title.common.course") }}
                </th>
                <th class="d-none d-lg-table-cell">
                  {{ $t("title.common.shot") }}
                </th>
                <th class="d-none d-md-table-cell">
                  {{ $t("title.common.date") }}
                </th>
                <th class="d-none d-sm-table-cell">
                  {{ $t("title.membership.scorecard") }}
                </th>
                <th class="th-arrow">&nbsp;</th>
              </tr>
            </thead>
            <tbody v-if="data_yn">
              <template v-for="(item, index) in items">
                <tr :key="index">
                  <td class="d-lg-table-cell text-start text-sm-center">
                    {{ item.coursename }}
                  </td>
                  <td class="d-none d-lg-table-cell">
                    {{ item.score | comma }}
                  </td>
                  <td class="d-none d-md-table-cell">
                    <p class="text-date">
                      <!-- 사용자 시간 -->
                      <dfn
                        class="time-zone-mark badge rounded-pill bg-orange"
                        title="Connect time"
                        >CT</dfn
                      >
                      {{
                        item.timezone_startdate.timezone_private | dateformat
                      }}
                    </p>
                    <p class="text-date">
                      <!-- 서비스 시간 -->
                      <dfn
                        class="time-zone-mark badge rounded-pill bg-green"
                        title="Standard time"
                        >ST</dfn
                      >

                      {{ item.timezone_startdate.timezone_common | dateformat }}
                    </p>
                  </td>
                  <td class="d-none d-sm-table-cell">
                    <button
                      type="button"
                      class="btn btn-outline-primary btn-xs"
                      @click="
                        $router.push({
                          name: 'MyScorecard',
                          params: { id: searchData.tournamentno },
                          query: { gameno: item.gameno },
                        })
                      "
                    >
                      {{ $t("button.membership.scorecard") }}
                    </button>
                  </td>
                  <td class="td-arrow" @click="toggle_tr(index)">
                    <img
                      src="/img/arrow_none_collapsed.png"
                      :class="{ 'btn-toggle-arrow': view_hide === index }"
                    />
                  </td>
                </tr>
                <tr
                  class="d-lg-none"
                  :class="{ show: view_hide === index }"
                  :key="index + 'r'"
                  v-if="view_hide === index"
                >
                  <td colspan="4">
                    <div class="toggle-content">
                      <div class="row">
                        <div class="col-4">
                          {{ $t("title.common.shot") }}
                        </div>
                        <div class="col-8">{{ item.score }}</div>
                      </div>

                      <div class="row d-md-none">
                        <div class="col-4">{{ $t("title.common.date") }}</div>
                        <div class="col-8">
                          <p class="text-date">
                            <!-- 사용자 시간 -->
                            <dfn
                              class="time-zone-mark badge rounded-pill bg-orange"
                              title="Connect time"
                              >CT</dfn
                            >
                            {{
                              item.timezone_startdate.timezone_private
                                | dateformat
                            }}
                          </p>
                          <p class="text-date">
                            <!-- 서비스 시간 -->
                            <dfn
                              class="time-zone-mark badge rounded-pill bg-green"
                              title="Standard time"
                              >ST</dfn
                            >
                            {{
                              item.timezone_startdate.timezone_common
                                | dateformat
                            }}
                          </p>
                        </div>
                      </div>

                      <div class="row d-sm-none">
                        <div class="col-4">
                          {{ $t("title.membership.scorecard") }}
                        </div>
                        <div class="col-8">
                          <button
                            type="button"
                            class="btn btn-outline-primary btn-xs"
                            @click="
                              $router.push({
                                name: 'MyScorecard',
                                params: { id: searchData.tournamentno },
                                query: { gameno: item.gameno },
                              })
                            "
                          >
                            {{ $t("button.membership.scorecard") }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="4" class="text-center">
                  {{ $t("text.common.no_data") }}
                </td>
              </tr>
            </tbody>
          </table>

          <b-pagination-nav
            :link-gen="linkGen"
            :number-of-pages="page_total"
            v-model="searchData.pageno"
            use-router
            aria-label="Page navigation"
            v-if="page_total > 1"
            first-class="prev"
            prev-class="prev"
            next-class="next"
            last-class="next"
            hide-ellipsis
            limit="10"
          >
            <template #first-text>
              <i class="material-icons">keyboard_double_arrow_left</i>
            </template>
            <template #prev-text>
              <i class="material-icons">navigate_before</i>
            </template>
            <template #next-text>
              <i class="material-icons">navigate_next</i>
            </template>
            <template #last-text>
              <i class="material-icons">keyboard_double_arrow_right</i>
            </template>
            <template #page="{ page, active }">
              <a v-if="active">{{ page }}</a>
              <a v-else>{{ page }}</a>
            </template>
          </b-pagination-nav>
        </div>

        <div class="btn-bottom-wrapper">
          <b-button
            size="xl"
            variant="outline-primary"
            :aria-label="$t('button.common.list')"
            @click="click_list()"
            >{{ $t("button.common.list") }}</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import tab from "@/components/membership/Tab";
import ax from "@/api/membership";
import "@/filter/common";
import { myMixin } from "@/mixin/myMixin";

export default {
  name: "MyTournamentsAttend",
  components: {
    location,
    tab,
  },
  data() {
    return {
      searchData: {
        pageno: parseInt(this.$route.query.page) || 1,
        tournamentno: this.$route.params.id,
      },
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      rows: 10,
      items: [],
      view_hide: null,
      tournamentinfo: {},
    };
  },
  methods: {
    get_membertournamentplaylist() {
      ax.get_membertournamentplaylist(
        this.searchData,
        (flag, data, code = "") => {
          // console.log(flag, data);
          if (flag) {
            if (data.tournamentplaylistcnt <= 0) {
              this.data_yn = false;
              // return false;
            } else {
              this.data_yn = true;
            }
            this.total_cnt = data.tournamentplaylistcnt;
            this.page_total = Math.ceil(data.tournamentplaylistcnt / this.rows);
            this.items = data.tournamentplaylist;
            this.tournamentinfo = data.tournamentinfo;
          } else {
            this.data_yn = false;
            this.page_total = 0;
            if (code == "300") {
              this.$notify({
                group: "alert",
                type: "warn",
                text: data,
                duration: 5000,
                speed: 1000,
              });
            }
            // alert(data);
          }
        }
      );
    },
    click_list() {
      this.$router.push({
        name: "MyTournaments",
        query: {
          startdate: this.$route.query.startdate,
          enddate: this.$route.query.enddate,
        },
      });
    },
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
  },
  created() {
    this.get_membertournamentplaylist();
  },
  watch: {
    "$route.query": function () {
      this.searchData.pageno = parseInt(this.$route.query.page) || 1;
      this.get_membertournamentplaylist();
    },
  },
  mixins: [myMixin],
};
</script>